import React from 'react';
import './App.css';
import { Drawer } from '@mui/material';
import AppDrawerNavItem from './AppDrawerNavItem';
import { Book, GitHub, YouTube } from '@mui/icons-material';

interface AppDrawerProps {
    isOpen: boolean,
    toggleDrawer: (desiredState: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void
}

function AppDrawer(props: AppDrawerProps) {
    return (
        <Drawer anchor='left' 
                open={props.isOpen} 
                onClose={props.toggleDrawer(false)}>
            <AppDrawerNavItem label='Vlogs' NavIcon={YouTube} linkUrl='https://www.youtube.com/@octetful'/>
            <AppDrawerNavItem label='Blogs' NavIcon={Book} linkUrl='https://blogs.octetful.com' />
            <AppDrawerNavItem label='Code' NavIcon={GitHub} linkUrl='https://github.com/octetful' />
        </Drawer>
    );
}

export default AppDrawer;