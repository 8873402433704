import React, { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import WipNotice from './WipNotice';
import AppFooter from './AppFooter';
import AppHeaderBar from './AppHeaderBar';
import AppDrawer from './AppDrawer';

const darkTheme = createTheme({
  palette: {
    primary: {
      main: '#673AB7',
    },
    background: {
      default: '#212121',
      paper: '#4CAF50'
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#212121'
    },
    divider: '#BDBDBD'
  },
});


function App() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = (desiredState: boolean) => 
    (event: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>) => {
    setDrawerOpen(desiredState)
  }
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <AppHeaderBar toggleDrawer={toggleDrawer} />
      <WipNotice />
      <AppFooter />
      <AppDrawer isOpen={drawerOpen} toggleDrawer={toggleDrawer} />
    </ThemeProvider>
  );
}

export default App;
