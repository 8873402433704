import React from 'react';
import workingSid from './images/working_sid.jpeg';
import Image from 'react-bootstrap/Image';
import { Typography, Paper } from '@mui/material';
import './App.css';

function WipNotice() {
    return (
        <div className="App" data-testid='wip-notice'>
            <Paper sx={{flex: 1, margin: "10px"}}>
            <Image src={workingSid} className='App-logo' />
            <Typography variant='body1'>
                This site is still under constructions, but you can still visit my <a href="https://blogs.octetful.com/" className="App-link">blog</a>, or checkout and perhaps follow the social links below.
            </Typography>
            </Paper>
      </div>
    );
}

export default WipNotice;