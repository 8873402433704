import React from 'react';
import './App.css';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

interface AppDrawerNavItemProps {
    label: string,
    linkUrl: string,
    NavIcon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
        muiName: string;
    }
}

function AppDrawerNavItem(props: AppDrawerNavItemProps) {
    const {label, linkUrl, NavIcon} = props;
    return (
        <List>
            <ListItem disablePadding>
                <ListItemButton href={linkUrl}>
                    <ListItemIcon>
                        <NavIcon />
                    </ListItemIcon>
                    <ListItemText>
                        {label}
                    </ListItemText>
                </ListItemButton>
            </ListItem>
        </List>
    );
}

export default AppDrawerNavItem;