import React from 'react';
import './App.css';
import { AppBar, Box, Typography, Toolbar, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

interface AppHeaderBarProps {
  toggleDrawer: (desiredState: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void
}

function AppHeaderBar(props: AppHeaderBarProps) {
    return (
        <Box sx={{flexGrow: 1}} className="App-bar">
          <AppBar position='static'>
            <Toolbar>
              <IconButton 
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={props.toggleDrawer(true)}>
                  <MenuIcon />
              </IconButton>
              <Typography variant='h3' 
                          component="a"
                          href='http://www.octetful.com'
                          noWrap
                          sx={{
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            color: 'inherit',
                            textDecoration: 'none',
                          }}>
                Octetful
              </Typography>
              
              
            </Toolbar>
          </AppBar>
        </Box>
    );
}

export default AppHeaderBar;