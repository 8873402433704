import React from 'react';
import { SocialIcon } from 'react-social-icons';
import './App.css';


function AppFooter() {
  return (
    <footer>
      <SocialIcon className="App-social-icon" url="https://www.facebook.com/octetful/" />
      <SocialIcon className="App-social-icon" url="https://twitter.com/octetful" />
      <SocialIcon className="App-social-icon" url="https://github.com/octetful" />
      <SocialIcon className="App-social-icon" url="https://www.youtube.com/@octetful" />
      <p>
        <small>(C) 2023, Octetful</small>
        <small>All rights reserved.</small>
      </p>
    </footer>
  );
}

export default AppFooter;